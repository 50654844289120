<template>
  <v-app>
    <HeaderComponent />
    <v-main class="mt-4">
      <v-container v-if="loading" class="d-flex align-center justify-center" style="min-height: 80vh;">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-container>
      <router-view v-else />
      <Alert />
    </v-main>
    <FooterComponent />
  </v-app>
</template>

<script>
import Alert from './components/Alert.vue';
import FooterComponent from './components/FooterComponent.vue';
import HeaderComponent from './components/HeaderComponent.vue';

export default {
  components: {
    HeaderComponent,
    FooterComponent,
    Alert,
  },
  data() {
    return {
      loading: true,
    };
  },
  created() {
    this.$router.beforeEach((to, from, next) => {
      this.loading = true;
      next();
    });

    this.$router.afterEach(() => {
      this.loading = false;
    });
  },
};
</script>

<style>
.v-progress-circular {
  transition: opacity 0.5s ease;
}
h1,
h2,
h3,
h4,
h5,
h6,
.v-btn__content {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

body,
p,
span,
div,
a,
li,
input,
button {
  font-family: 'Mulish', sans-serif;
  font-weight: 400;
}
body{
  overflow: hidden;
}
</style>
