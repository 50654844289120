import { createStore } from 'vuex';

export default createStore({
  state: {
    user: null,
    token: null,
    alertMessage: null,
    alertColor: 'success', // Cor padrão
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setToken(state, token) {
      state.token = token;
    },
    setAlertMessage(state, { message, color = 'success' }) {
      state.alertMessage = message;
      state.alertColor = color; // Agora o color está definido corretamente
    },
    clearAlertMessage(state) {
      state.alertMessage = null;
      state.alertColor = 'success';
    },
  },
  getters: {
    getUser: (state) => state.user,
  },
});
