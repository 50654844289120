<template>
    <v-snackbar v-model="visible" :color="alertColor" top right :timeout="3000">
      {{ alertMessage }}
    </v-snackbar>
  </template>
  
  <script>
  import { mapState, mapMutations } from 'vuex';
  
  export default {
    name: 'AlertMsg',
    computed: {
      ...mapState({
        alertMessage: (state) => state.alertMessage,
        alertColor: (state) => state.alertColor,
      }),
      visible: {
        get() {
          return !!this.alertMessage;
        },
        set(val) {
          if (!val) {
            this.clearAlertMessage();
          }
        },  
      },
    },
    methods: {
      ...mapMutations(['clearAlertMessage']),
    },
  };
  </script>
  