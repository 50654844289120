import { createRouter, createWebHistory } from 'vue-router';
import store from './store';
import axios from 'axios';

const routes = [
  {
    path: "/",
    component: () => import("./pages/HomePage.vue"),
    name: 'HomePage',
    // meta: {
    //   publicName: 'Página Inicial',
    // }
  },
  {
    path: "/intercambio",
    component: () => import("./pages/Intercambio.vue"),
    name: 'IntercambioPage',
    // meta: {
    //   publicName: 'Intercâmbio',
    // }
  },
  {
    path: "/sobre-nos",
    component: () => import("./pages/SobreNos.vue"),
    name: 'SobrePage',
    // meta: {
    //   publicName: 'Sobre Nós',
    // }
  },
  {
    path: "/contato",
    component: () => import("./pages/Contato.vue"),
    name: 'ContatoPage',
    // meta: {
    //   publicName: 'Contato',
    // }
  },
  {
    path: '/blog/:title',
    name: 'BlogDetail',
    component: () => import("./pages/BlogDetail.vue"),
    props: true
  },
  {
    path: "/blogs",
    component: () => import("./pages/Blogs.vue"),
    name: 'BlogsPage',
    // meta: {
    //   publicName: 'Blogs',
    // }
  },

  {
    path: "/cursos",
    component: () => import("./pages/Cursos.vue"),
    name: 'CursosPage',
  },
  {
    path: "/cursos/ingles",
    component: () => import("./pages/cursos/Ingles.vue"),
    name: 'CursoIngles',
  },
  {
    path: "/cursos/espanhol",
    component: () => import("./pages/cursos/Espanhol.vue"),
    name: 'CursoEspanhol',
  },
  {
    path: "/cursos/japones",
    component: () => import("./pages/cursos/Japones.vue"),
    name: 'CursoJapones',
  },
  {
    path: "/cursos/chines",
    component: () => import("./pages/cursos/Chines.vue"),
    name: 'CursoChines',
  },
  {
    path: "/cursos/italiano",
    component: () => import("./pages/cursos/Italiano.vue"),
    name: 'CursoItaliano',
  },
  {
    path: "/cursos/frances",
    component: () => import("./pages/cursos/Frances.vue"),
    name: 'CursoFrances',
  },
  {
    path: "/cursos/libras",
    component: () => import("./pages/cursos/Libras.vue"),
    name: 'CursoLibras',
  },
  {
    path: "/cursos/supletivo",
    component: () => import("./pages/cursos/Supletivo.vue"),
    name: 'CursoSupletivo',
  },
  {
    path: "/cursos/toeic",
    component: () => import("./pages/cursos/Toeic.vue"),
    name: 'CursoToeic',
  },

  // -------------------------------------------------
  { path: '/register', component: () => import("./pages/Register.vue"),},
  { path: '/login',  component: () => import("./pages/Login.vue"),},
  {
    path: '/admin',
    name: 'Admin',
    component: () => import("./pages/admin/AdminPage.vue"),
    meta: { requiresAdmin: true },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0 };
    }
  },
});

router.beforeEach(async (to, from, next) => {
  try {
    const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/auth/me`);
    const userRole = response.data.role;
    const user = response.data;
    store.commit('setUser', user);

    if (to.matched.some(record => record.meta.requiresAdmin) && userRole !== 'admin') {
      store.commit('setAlertMessage', 'Seu usuário não tem acesso ao Painel de administração');
      setTimeout(() => store.commit('clearAlertMessage'), 3000);
      return next('/');
    }
    next();
  } catch (error) {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      store.commit('setAlertMessage', 'Você precisa estar logado para acessar esta página');
      setTimeout(() => store.commit('clearAlertMessage'), 3000);
      return next('/login');
    }
    next();
  }
});

export default router;
